import Vue from "vue"

import { mapState, mapMutations } from "vuex"



Vue.mixin({
    methods: {
        gotowhere(name) {
            this.$router.push({ name })
        },
        ...mapMutations(['changeUserInfo','changeGradeType','changeAgencyInfo'])

    },
    mounted() {

    },
    computed: {
        ...mapState(['userInfo','gradeType','agencyInfo'])
    },
    data() {
        return {


        }
    }
})