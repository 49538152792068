import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],   //调用 Vuex 持久化
  state: {
    userInfo: null,
    gradeType:{},
    agencyInfo:{}
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = payload
    },
    changeGradeType(state, payload) {
      state.gradeType = payload
    },
    changeAgencyInfo(state, payload) {
      state.agencyInfo = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
