import axios from "axios";

// 设置 axios 的基本信息
// const instance = axios.create({
//     baseURL: '',
//     timeout: 60000,
//     headers: {'X-Custom-Header': 'foobar'}
// });

const AUTH_TOKEN = "";
export const baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = baseURL; // 应用的基路径
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;  // Token 身份授权
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded"; // 文件格式

// 配置Loading

import { Loading, Message } from "element-ui";
import router from "@/router";
let loading = null;
export function showLoading(msg) {
  msg = msg || "加载中...";
  loading && loading.close();
  loading = Loading.service({
    lock: true,
    text: msg,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
}

export function showSuccess(msg) {
  msg = msg || "success";
  loading && loading.close();
  // Message.success({
  //     showClose: true,
  //     message: msg,
  // })
}

export function showFail(msg) {
  msg = msg || "Fail";
  loading && loading.close();
  Message.error({
    showClose: true,
    message: msg,
  });
}

// 拦截器   Interceptors
// 请求发送之前的拦截
axios.interceptors.request.use(
  function (config) {
    // 请求发送成功

    //把接收到的token通过headers请求头发送给后端
    var token = sessionStorage.token ? sessionStorage.token : "";
    config.headers.token = token;
    var host = sessionStorage.host ? sessionStorage.host : "";
    config.headers["agent-host"] = host;
    showLoading();
    return config;
  },
  function (error) {
    showFail("请求失败");
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应的拦截图
axios.interceptors.response.use(
  
  function (response) {
    const {
      config: { url },
    } = response;
    if (url.includes("/export")) {
      loading && loading.close();
    } else {
      if(response.data.code == 1){
        showSuccess(response.data.msg);
      }else if(response.data.code == 2){
        loading && loading.close();
      }else{
        showFail(response.data.msg);
      }
    }

    return response;
  },
  function (error) {
    // 返回失败
    const {
      status,
      data: { msg },
    } = error.response;
    showFail(msg);
    if (status == 401) {
      setTimeout(() => {
        router.replace("/login");
      }, 3000);
    }
    return Promise.reject(error);
  }
);

const get = (url, params, config) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
        ...config,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const post = (url, body, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, body, {
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { axios, get, post };
