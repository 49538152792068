import { axios, get, post } from "./ajax";
const BasePath = "/admin/v1/";
export const http = {
  // 用户类型
  gradeList: (params) => get(BasePath + "user/gradeList", params),
  // 登录
  login: (params) => post(BasePath + "auth/accountLogin", params),
  // 注册
  register: (params) => post(BasePath + "auth/register", params),
  // 代理申请
  apply: (params) => post(BasePath + "agent/apply", params),
  // 登出
  logout: (params) => post(BasePath + "auth/logout", params),
  // 用户信息
  info: (params) => get(BasePath + "auth/info", params),
  // 商品状态列表
  statusText: (params) => get(BasePath + "goods/statusText", params),
  // 上传文件
  upload: (params) => post(BasePath + "upload/upload", params),
  // 判断使用的搜索
  beforeRequest: (params) => get(BasePath + "obapi/beforeRequest", params),
  // 关键词商品列表
  getItemList: (params) => get(BasePath + "obapi/getItemList", params),
  // 商品图片搜索
  getItemSearchImg: (params) =>
    get(BasePath + "obapi/getItemSearchImg", params),
  // 商品详情
  getItem: (params) => get(BasePath + "obapi/getItem", params),
  // 添加商品
  addGoods: (params) => post(BasePath + "goods/addGoods", params),
  // 后台商品列表
  getList: (params) => get(BasePath + "goods/getList", params),
  // 后台删除商品
  delGoods: (params) => post(BasePath + "goods/delGoods", params),
  // 后台导出商品列表
  export: (params) =>
    get(BasePath + "goods/export", params, {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob", // 设置 responseType 为 blob
    }),
  // 后台导入csv采购文件
  import: (params) =>
    post(BasePath + "goods/import", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  // csv采购文件模板
  template: (params) => get(BasePath + "goods/template", params),
  // 后台创建采购订单
  createOrder: (params) => post(BasePath + "Custom/createOrder", params),
  // 后台支付采购订单
  payOrder: (params) => post(BasePath + "Custom/payOrder", params),
  // 后台同步物流信息
  logistics: (params) => post(BasePath + "Custom/logistics", params),
  // 系统管理员 普通用户列表
  userPage: (params) => get(BasePath + "user/userPage", params),
  // 系统管理员 代理用户列表
  agentPage: (params) => get(BasePath + "user/agentPage", params),
  // 代理用户 下级用户列表
  myUserPage: (params) => get(BasePath + "user/myUserPage", params),
  // 授权1688账号
  callBack1688: (params) => get(BasePath + "Custom/callBack1688", params),
  // API列表
  apiList: (params) => get(BasePath + "common/api/list", params),
  // 保存系统配置
  setting: (params) => post(BasePath + "agent/setting", params),
  // 系统配置详情
  setupDetail: (params) => get(BasePath + "agent/detail", params),
  // 修改密码
  updatePassword: (params) => post(BasePath + "auth/updatePassword", params),
  // 获取代理列表状态
  statusList: (params) => get(BasePath + "agent/statusList", params),
  // 获取代理列表
  agencyPage: (params) => get(BasePath + "agent/page", params),
  // 代理列表审核操作
  upStatus: (params) => post(BasePath + "agent/upStatus", params),
  // 代理信息详情
  init: (params) => get(BasePath + "common/init", params),
  // 直接登录
  directLogin: (params) => get(BasePath + "user/directLogin", params),
  // 用户管理编辑
  updateUserPassword: (params) => post(BasePath + "user/updatePassword", params),
  // 1688授权用户列表
  authorizedUser: (params) => get(BasePath + "user/custom/lists", params),
  // 1688收货地址列表
  addressLists: (params) => get(BasePath + "Custom/addressLists", params),
  // 删除1688授权账号
  deleteAuthorizedUser: (params) => post(BasePath + "user/custom/delete", params),
  // 新增1688收货地址
  createAddress: (params) => post(BasePath + "Custom/createAddress", params),
  // 删除本系统新增地址
  deleteAddress: (params) => post(BasePath + "user/address/delete", params),
};
