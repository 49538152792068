import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import moment from "moment"
moment.locale();         // zh-cn
Vue.prototype.$moment = moment;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import "@/components"

import "@/assets/styles/index.scss"

import "@/utils/mixin"

import { axios } from "@/api/ajax"
Vue.prototype.$axios = axios

import { http } from "@/api"
Vue.prototype.$ajax = http

import { baseURL } from "@/api/ajax"
Vue.prototype.$baseURL = baseURL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
