import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/view/register/register.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/view/main/index.vue"),
    redirect: "/main/purchasingCenter",
    children: [
      {
        path: "container",
        name: "container",
        component: () => import("@/view/subpage/container.vue"),
      },
      {
        path: "userControl",
        name: "userControl",
        component: () => import("@/view/subpage/userControl.vue"),
      },
      {
        path: "productList",
        name: "productList",
        component: () => import("@/view/subpage/productList.vue"),
      },
      {
        path: "wholesaleList",
        name: "wholesaleList",
        component: () => import("@/view/subpage/wholesaleList.vue"),
      },
      {
        path: "purchasingCenter",
        name: "purchasingCenter",
        component: () => import("@/view/subpage/purchasingCenter.vue"),
      },
      {
        path: "systemSetup",
        name: "systemSetup",
        component: () => import("@/view/subpage/systemSetup.vue"),
      },
      {
        path: "agentApplication",
        name: "agentApplication",
        component: () => import("@/view/subpage/agentApplication.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});

export default router;
