<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted(){
    let host = '';
    if(location.host.includes('localhost')){
      host =  'chjc.dev.daigouxt.com';
    }else{
      host = location.host;
    }

    this.$ajax.gradeList().then(res=>{
      this.changeGradeType(res.data)
    })
    this.$ajax.init().then(res=>{
      this.changeAgencyInfo(res.data);
      if(res.data.host){
        sessionStorage.setItem('host',res.data.host)
      }else{
        sessionStorage.setItem('host',host)
      }
      
    })
  }
}
</script>

<style>

</style>
